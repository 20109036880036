import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="203" height="10" viewBox="0 0 203 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.7409 10C33.5439 9.07169 2.81297 10.1081 0.579262 9.82831C-1.24435 9.45317 1.66994 2.84689 3.36267 1.87407C3.956 1.52436 4.64531 1.47351 6.22461 1.63247C8.72881 1.93131 41.1089 1.33997 41.1089 1.33997C41.5364 1.54344 42.8191 0.856744 43.0547 1.10472C43.7963 1.68332 96.2798 0.894891 100.756 0.8631C103.47 0.844025 101.323 1.23824 104.517 1.07928C111.322 0.761364 126.156 1.06657 131.312 0.704142C135.44 0.405302 136.329 0.392594 140.291 0.63421C144.339 0.850392 165.865 0.538832 168.326 0.074676C168.928 -0.0524901 169.39 -0.00797814 169.39 0.138263C169.39 0.170054 184.564 0.278142 184.633 0.392591C184.747 0.538832 185.209 0.608769 185.637 0.507036C186.431 0.354437 202.582 -0.198737 202.835 0.227269C203.629 1.44806 201.404 6.64915 199.528 7.91446C197.608 9.26878 187.068 7.74915 178.595 8.56302L139.148 8.64568L130.693 9.15433C129.724 8.71561 125.999 9.82196 123.747 9.03353C122.796 8.72197 116.078 8.67745 114.594 9.00809C113.303 9.26242 78.9336 8.62023 75.4086 9.50403C69.1262 8.76647 43.648 9.82197 37.7409 10Z" fill="#FFA700" />
    </svg>


  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="287" height="10" viewBox="0 0 287 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.3578 10C47.4242 9.07169 3.97696 10.1081 0.818957 9.82831C-1.75926 9.45317 2.36095 2.84689 4.75413 1.87407C5.59297 1.52436 6.56751 1.47351 8.80032 1.63247C12.3407 1.93131 58.1195 1.33997 58.1195 1.33997C58.7239 1.54344 60.5373 0.856744 60.8704 1.10472C61.9189 1.68332 136.12 0.894891 142.448 0.8631C146.285 0.844025 143.25 1.23824 147.765 1.07928C157.387 0.761364 178.358 1.06657 185.649 0.704142C191.483 0.405302 192.742 0.392594 198.342 0.63421C204.066 0.850392 234.499 0.538832 237.978 0.074676C238.829 -0.0524901 239.483 -0.00797814 239.483 0.138263C239.483 0.170054 260.935 0.278142 261.034 0.392591C261.194 0.538832 261.848 0.608769 262.452 0.507036C263.575 0.354437 286.409 -0.198737 286.766 0.227269C287.889 1.44806 284.743 6.64915 282.091 7.91446C279.377 9.26878 264.475 7.74915 252.497 8.56302L196.726 8.64568L184.773 9.15433C183.403 8.71561 178.136 9.82196 174.953 9.03353C173.609 8.72197 164.11 8.67745 162.013 9.00809C160.187 9.26242 111.596 8.62023 106.612 9.50403C97.7302 8.76647 61.7092 9.82197 53.3578 10Z" fill="#FFA700" />
    </svg>
  </span>
)
