import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "CRM para cobranzas",
      description: "Segmenta a los clientes en función de su historial de pagos, preferencias de comunicación, nivel de riesgo, entre otros datos relevantes. Personaliza las campañas de cobranza a las necesidades específicas de cada segmento de deudores, y aumenta la probabilidad de éxito en la recuperación.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Analiza las interacciones",
      description: "Analiza las interacciones entre agentes y clientes, identificando patrones comunes, tendencias y áreas de mejora. Utiliza información valiosa para optimizar los scripts de conversación, entrenar a los agentes y mejorar la efectividad de las estrategias de cobranza.",
      image: imageTwoMobile,
      background: "#FADEDD"
    },
    {

      title: "Capacidades de autoservicio",
      description: "Ofrece a los deudores opciones de autoservicio mediante chatbots e IVR para consultar monto de deuda, fechas de pago, entre otros. Reduce la carga de trabajo de los agentes y brinda a los clientes mayor autonomía y conveniencia.",
      image: imageFourMobile,

      background: "#EEEAFB"
    },
    {
      title: "Actualiza la información de pago",
      description: "Integra tus sistemas de procesamiento de pagos y bases de datos financieras para actualizar automáticamente la información de pago de los clientes, como cambios en la información de la tarjeta de crédito o cuentas bancarias. Asegura que los datos de pago estén siempre actualizados.",
      image: imageThreeMobile,
      background: "#FADEDD"
    },
  ]

  return (
    <section className="container-experience-cobros">
      <p className="container-experience-cobros-title">
        Mejora la contactabilidad con tus deudores para garantizar una recuperación <TitleUnderlineOne underline="más efectiva" /> de los <TitleUnderlineTwo underline="pagos pendientes" /></p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-cobros-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-cobros-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-cobros-cardsOne-card-title">CRM para cobranzas</p>
            <p className="container-experience-cobros-cardsOne-card-description">
              Segmenta a los clientes en función de su historial de pagos, preferencias de comunicación, nivel de riesgo, entre otros datos relevantes. Personaliza las campañas de cobranza a las necesidades específicas de cada segmento de deudores, y aumenta la probabilidad de éxito en la recuperación.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-cobros-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-cobros-cardsOne-column">
          {/* columna */}
          <section className="container-experience-cobros-cardsOne-card" style={{ background: "#FADEDD", padding: "32px 32px 20px" }}>
            <section>
              <p className="container-experience-cobros-cardsOne-card-title">Analiza las interacciones</p>
              <p className="container-experience-cobros-cardsOne-card-description">
                Analiza las interacciones entre agentes y clientes, identificando patrones comunes, tendencias y áreas de mejora. Utiliza información valiosa para optimizar los scripts de conversación, entrenar a los agentes y mejorar la efectividad de las estrategias de cobranza.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-cobros-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-cobros-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-cobros-cardsTwo-card" style={{ background: "#FADEDD", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-cobros-cardsTwo-card-title">Actualiza la información de pago</p>
            <p className="container-experience-cobros-cardsTwo-card-description">
              Integra tus sistemas de procesamiento de pagos y bases de datos financieras para actualizar automáticamente la información de pago de los clientes, como cambios en la información de la tarjeta de crédito o cuentas bancarias. Asegura que los datos de pago estén siempre actualizados.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-cobros-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-cobros-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-cobros-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-cobros-cardsTwo-card-title">Capacidades de autoservicio</p>
              <p className="container-experience-cobros-cardsTwo-card-description">
                Ofrece a los deudores opciones de autoservicio mediante chatbots e IVR para consultar monto de deuda, fechas de pago, entre otros. Reduce la carga de trabajo de los agentes y brinda a los clientes mayor autonomía y conveniencia.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-cobros-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-cobros-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-cobros-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-cobros-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-cobros-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-cobros-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
